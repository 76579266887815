import React from 'react';
import { 
  Box 
} from '@mui/material';
import './BottomAppBarComponent.css';
import { Icon } from '@iconify/react';

import { Link } from 'react-router-dom';

import { useLocation } from "react-router-dom";

export default function BottomAppBarComponent() {

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <>
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Box className="main-header bottom-header" sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', textAlign: 'center', backgroundColor: '#EFF4FF' }}>
            
            <ul>
              <li className={splitLocation[1] === "" ? "active" : ""}>
              <Link exact to="/puja-gallery" className="bottom-main-menu">
                <Icon className='ic-icons' icon="ri:flower-line" />
                Book Puja
              </Link>
              </li>
              {/* <li className={splitLocation[1] === "" ? "active" : ""}>
              <Link to="/get-books" className="bottom-main-menu">
                <Icon className='ic-icons' icon="fluent:book-24-regular" />
                Get Books
              </Link>
              </li> */}
              {/* <li className={splitLocation[1] === "" ? "active" : ""}>
              <Link to="/make-a-donation" className="bottom-main-menu">
                <Icon className='ic-icons' icon="mdi:donation-outline" />
                Make a Donation
              </Link>
              </li> */}
            </ul>
        </Box>
    </Box>
    </>
  )
}
