import * as React from 'react';
import { 
  Box, 
  CssBaseline, 
  Container, 
  Typography, 
  Grid
} from '@mui/material';
import '../HomeComponent/HomeComponent.css';
import { Icon } from '@iconify/react';

import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
// import ModelComponent from '../../common/ModelComponent/ModelComponent';

export default function HomeComponent() {
  const TempleLogo = require('../../assets/god-image.jpg');
  return (
    <>
      <Box>
        <CssBaseline />
          <Container className='main-container'>
            <Box className="temple-home-page">
              <Typography className='main-title' variant="h4" sx={{color: '#3875BF', fontWeight: '600', lineHeight: 1.5, textAlign: 'center', py: 3 }}>
                  Welcome to Sarvamangala Sri <br /> Rajarajeswari Ashram 
              </Typography>
              <Box className="main-header" sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', textAlign: 'center' }}>
                <HashLink  className='header-menu' to="puja-gallery" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>
                  <Icon className='ic-icons' icon="ri:flower-line" />
                  Book Puja
                </HashLink>
                {/* <HashLink  className='header-menu' to="get-books" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500}}>
                  <Icon className='ic-icons' icon="fluent:book-24-regular"  />
                  Get Books
                </HashLink> */}
                {/* <HashLink  className='header-menu' to="make-a-donation" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>
                  <Icon className='ic-icons' icon="mdi:donation-outline" />
                  Make a Donation
                </HashLink> */}
              </Box>
              <Box className="temple-title" sx={{marginTop: '40px'}}>
                <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                  <Grid item xs={12} md={5}>
                    <Box>
                      <img 
                          className='temple-logo'
                          src={String(TempleLogo)}
                          alt="Temple-Logo"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="home-page-content">
                      <Typography sx={{ color: '#3875BF', pt: 2, pb: 3, fontWeight: 600, fontSize: '20px' }}>About the Ashram</Typography>
                      <Box sx={{ mb: 4 }}>
                        <Typography sx={{ color: '#686868', fontWeight: '500', fontSize: '18px' }}>
                          Sarvamangala Sri Rajarajeswari Ashram <br /> is a 52 year old religious center based in <br /> Chennai, India.
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 4 }}>
                        <Typography sx={{ color: '#686868', fontWeight: '500', fontSize: '18px' }}>
                          18/29 16th Street TG Nagar <br /> Nanganallur Chennai INDIA 600061
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 4 }}>
                        <Typography sx={{ color: '#686868', fontWeight: '500', fontSize: '18px' }}>
                          99625 14134
                        </Typography>
                      </Box>
                      <Box>
                        <Link className='template-email' to="mailto:sridevitemple@gmail.com">
                          sridevitemple@gmail.com
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box className='quick-links' sx={{ pt: 4 }}>
                  <Typography sx={{ color: '#3875BF', pb: 3, fontWeight: 600, fontSize: '20px' }}>Quick Links</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={5} md={2}>
                      <Link className='quick-links-box' to="https://www.youtube.com/@sridevitemple/videos" target='_blank'>
                        <Box className='ql-content' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #686868', borderRadius: '4px', p: 1, backgroundColor: '#ffffff', color: '#686868', cursor: 'pointer' }}>
                              <Icon className='ql-ic-icons youtube-icon' icon="mingcute:youtube-fill" />
                              Video
                        </Box>
                      </Link>
                    </Grid>
                    <Grid item xs={5} md={2}>
                      <Link className='quick-links-box' to="https://www.youtube.com/@sridevitemple/shorts" target='_blank'>
                        <Box className='ql-content' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #686868', borderRadius: '4px', p: 1, backgroundColor: '#ffffff', color: '#686868', cursor: 'pointer' }}>
                            <Icon className='ql-ic-icons youtube-shorts-icon' icon="simple-icons:youtubeshorts" />
                            Shorts
                        </Box>
                      </Link>
                    </Grid>
                    <Grid item xs={5} md={2}>
                      <Link className='quick-links-box' to="https://www.youtube.com/channel/UCEwcTtQYsBqca48uH55DggQ?sub_confirmation=1" target='_blank'>
                        <Box className='ql-content' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #686868', borderRadius: '4px', p: 1, backgroundColor: '#ffffff', color: '#686868', cursor: 'pointer' }}>
                            <Icon className='ql-ic-icons subscribe-icon' icon="basil:notification-solid" />
                            Subscribe
                        </Box>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
                <FooterComponent/>
              </Box>
            </Box>
          </Container>
      </Box>
    </>
  )  
}
